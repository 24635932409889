export const Api = [
    0,
    7,
    8,
    10,
    12,
    13,
    17,
    22,
    23,
    24,
    26,
    28,
    31,
    33,
    34,
    36,
    37,
    38,
    39,
    42,
    43,
    44,
    46,
    48,
    50,
    54,
    55,
    59,
    63,
    64,
    67,
    72,
    75,
    76,
    80,
    82,
    85,
    86,
    89,
    92,
    94,
    96,
    97,
    100,
    102,
    110,
    111,
    115,
    117,
    120,
    121,
    123,
    127,
    132,
    134,
    136,
    138,
    141,
    144,
    145,
    147,
    152,
    154,
    158,
    159,
    162,
    163,
    164,
    168,
    169,
    172,
    174,
    181,
    182,
    183,
    184,
    185,
    186,
    189,
    197,
    201,
    205,
    206,
    208,
    210,
    213,
    218,
    223,
    225,
    227,
    229,
    231,
    232,
    233,
    238,
    239,
    241,
    244,
    247,
    249,
    257,
    259,
    261,
    262,
    264,
    265,
    268,
    272,
    275,
    277,
    280,
    281,
    284,
    285,
    286,
    287,
    294,
    295,
    297,
    302,
    311,
    312,
    313,
    316,
    319,
    322,
    323,
    325,
    331,
    332,
    333,
    334,
    343,
    347,
    349,
    353,
    355,
    357,
    358,
    364,
    365,
    368,
    369,
    371,
    372,
    373,
    377,
    378,
    380,
    383,
    385,
    388,
    390,
    397,
    400,
    403,
    410,
    413,
    414,
    416,
    419,
    420,
    421,
    425,
    429,
    430,
    431,
    434,
    436,
    437,
    438,
    439,
    440,
    443,
    448,
    450,
    455,
    457,
    460,
    461,
    462,
    464,
    466,
    467,
    469,
    472,
    474,
    479,
    480,
    485,
    486,
    491,
    493,
    495,
    499,
    506,
    512,
    514,
    516,
    518,
    519,
    520,
    521,
    523,
    524,
    526,
    527,
    528,
    529,
    530,
    533,
    534,
    535,
    541,
    545,
    547,
    551,
    559,
    560,
    562,
    566,
    568,
    569,
    571,
    573,
    574,
    576,
    579,
    581,
    582,
    583,
    585,
    587,
    589,
    597,
    599,
    604,
    605,
    608,
    611,
    612,
    614,
    615,
    617,
    619,
    620,
    621,
    623,
    631,
    632,
    635,
    636,
    638,
    639,
    640,
    645,
    647,
    650,
    651,
    652,
    655,
    656,
    657,
    660,
    661,
    663,
    665,
    666,
    671,
    674,
    675,
    676,
    677,
    678,
    679,
    680,
    681,
    682,
    685,
    686,
    687,
    689,
    690,
    695,
    699,
    702,
    706,
    707,
    710,
    712,
    713,
    714,
    719,
    721,
    722,
    724,
    726,
    728,
    733,
    734,
    737,
    738,
    739,
    741,
    742,
    743,
    746,
    750,
    751,
    752,
    756,
    758,
    759,
    760,
    764,
    765,
    768,
    769,
    770,
    771,
    772,
    773,
    777,
    788,
    790,
    793,
    800,
    801,
    804,
    807,
    810,
    815,
    817,
    819,
    821,
    825,
    827,
    828,
    829,
    830,
    831,
    833,
    834,
    835,
    836,
    837,
    839,
    842,
    844,
    845,
    848,
    853,
    855,
    856,
    857,
    859,
    861,
    863,
    872,
    873,
    875,
    878,
    881,
    888,
    889,
    891,
    894,
    895,
    900,
    902,
    903,
    904,
    909,
    911,
    914,
    923,
    925,
    926,
    928,
    929,
    931,
    932,
    934,
    936,
    938,
    941,
    942,
    943,
    947,
    948,
    949,
    951,
    952,
    953,
    954,
    965,
    968,
    970,
    974,
    977,
    978,
    981,
    983,
    985,
    986,
    990,
    997,
    998,
    1005,
    1008,
    1010,
    1011,
    1012,
    1017,
    1018,
    1019,
    1021,
    1028,
    1031,
    1039,
    1041,
    1044,
    1046,
    1048,
    1053,
    1054,
    1056,
    1058,
    1065,
    1066,
    1067,
    1068,
    1070,
    1071,
    1072,
    1079,
    1081,
    1082,
    1085,
    1087,
    1092,
    1093,
    1096,
    1099,
    1101,
    1102,
    1104,
    1108,
    1115,
    1119,
    1120,
    1123,
    1124,
    1125,
    1126,
    1133,
    1135,
    1137,
    1138,
    1145,
    1150,
    1152,
    1157,
    1161,
    1163,
    1164,
    1170,
    1173,
    1174,
    1181,
    1184,
    1186,
    1187,
    1188,
    1195,
    1198,
    1199,
    1200,
    1201,
    1204,
    1212,
    1213,
    1214,
    1217,
    1218,
    1220,
    1222,
    1223,
    1226,
    1228,
    1229,
    1234,
    1239,
    1240,
    1241,
    1242,
    1244,
    1247,
    1249,
    1251,
    1252,
    1259,
    1271,
    1275,
    1276,
    1277,
    1279,
    1280,
    1281,
    1282,
    1285,
    1286,
    1287,
    1288,
    1289,
    1291,
    1293,
    1297,
    1298,
    1301,
    1305,
    1306,
    1312,
    1313,
    1315,
    1317,
    1319,
    1322,
    1325,
    1326,
    1328,
    1334,
    1338,
    1339,
    1341,
    1345,
    1349,
    1354,
    1355,
    1357,
    1362,
    1365,
    1369,
    1370,
    1375,
    1376,
    1377,
    1379,
    1385,
    1389,
    1390,
    1392,
    1393,
    1394,
    1396,
    1400,
    1401,
    1403,
    1408,
    1411,
    1416,
    1418,
    1420,
    1423,
    1425,
    1426,
    1428,
    1429,
    1434,
    1436,
    1438,
    1439,
    1440,
    1441,
    1442,
    1445,
    1448,
    1449,
    1453,
    1457,
    1459,
    1461,
    1463,
    1465,
    1466,
    1470,
    1471,
    1472,
    1473,
    1483,
    1484,
    1485,
    1487,
    1489,
    1492,
    1494,
    1496,
    1497,
    1501,
    1502,
    1504,
    1508,
    1510,
    1511,
    1512,
    1514,
    1517,
    1518,
    1525,
    1528,
    1530,
    1532,
    1534,
    1535,
    1539,
    1540,
    1545,
    1548,
    1550,
    1556,
    1558,
    1562,
    1566,
    1567,
    1568,
    1569,
    1573,
    1579,
    1580,
    1582,
    1583,
    1586,
    1587,
    1588,
    1589,
    1591,
    1592,
    1594,
    1595,
    1596,
    1597,
    1602,
    1605,
    1606,
    1607,
    1608,
    1611,
    1613,
    1615,
    1616,
    1618,
    1621,
    1624,
    1627,
    1633,
    1636,
    1637,
    1638,
    1639,
    1642,
    1643,
    1644,
    1645,
    1649,
    1653,
    1658,
    1660,
    1665,
    1669,
    1674,
    1676,
    1677,
    1678,
    1681,
    1683,
    1686,
    1688,
    1689,
    1691,
    1694,
    1696,
    1699,
    1701,
    1705,
    1706,
    1720,
    1725,
    1733,
    1737,
    1739,
    1741,
    1748,
    1749,
    1750,
    1751,
    1754,
    1757,
    1759,
    1762,
    1763,
    1767,
    1768,
    1769,
    1770,
    1771,
    1772,
    1781,
    1783,
    1787,
    1791,
    1794,
    1797,
    1799,
    1800,
    1801,
    1803,
    1806,
    1807,
    1809,
    1812,
    1817,
    1818,
    1819,
    1821,
    1828,
    1829,
    1830,
    1831,
    1834,
    1836,
    1838,
    1840,
    1841,
    1842,
    1846,
    1854,
    1856,
    1858,
    1864,
    1866,
    1867,
    1868,
    1870,
    1871,
    1874,
    1883,
    1890,
    1893,
    1895,
    1902,
    1904,
    1906,
    1908,
    1909,
    1911,
    1913,
    1914,
    1915,
    1919,
    1920,
    1922,
    1923,
    1924,
    1925,
    1926,
    1928,
    1929,
    1931,
    1944,
    1951,
    1953,
    1954,
    1956,
    1958,
    1961,
    1962,
    1969,
    1972,
    1974,
    1978,
    1981,
    1984,
    1985,
    1986,
    1988,
    1989,
    1990,
    1993,
    1994,
    1997,
    1998,
    1999,
    2000,
    2002,
    2003,
    2004,
    2007,
    2008,
    2009,
    2010,
    2011,
    2013,
    2014,
    2016,
    2019,
    2021,
    2022,
    2023,
    2024,
    2030,
    2040,
    2041,
    2045,
    2047,
    2048,
    2052,
    2053,
    2054,
    2056,
    2058,
    2061,
    2066,
    2067,
    2068,
    2073,
    2074,
    2079,
    2085,
    2086,
    2090,
    2092,
    2093,
    2096,
    2097,
    2099,
    2100,
    2101,
    2102,
    2105,
    2107,
    2111,
    2113,
    2114,
    2119,
    2121,
    2122,
    2124,
    2125,
    2126,
    2127,
    2129,
    2133,
    2135,
    2136,
    2138,
    2141,
    2142,
    2145,
    2146,
    2148,
    2152,
    2158,
    2160,
    2164,
    2166,
    2167,
    2170,
    2175,
    2176,
    2178,
    2180,
    2181,
    2182,
    2183,
    2185,
    2187,
    2188,
    2191,
    2193,
    2194,
    2207,
    2209,
    2211,
    2215,
    2218,
    2220,
    2222,
    2225,
    2227,
    2228,
    2229,
    2230,
    2231,
    2232,
    2235,
    2245,
    2248,
    2249,
    2252,
    2255,
    2256,
    2257,
    2259,
    2260,
    2262,
    2266,
    2270,
    2273,
    2274,
    2277,
    2279,
    2281,
    2283,
    2285,
    2287,
    2288,
    2291,
    2299,
    2303,
    2307,
    2313,
    2314,
    2316,
    2317,
    2318,
    2319,
    2320,
    2322,
    2323,
    2326,
    2327,
    2329,
    2331,
    2332,
    2333,
    2335,
    2337,
    2341,
    2347,
    2348,
    2349,
    2353,
    2358,
    2363,
    2364,
    2365,
    2366,
    2367,
    2368,
    2370,
    2371,
    2375,
    2379,
    2380,
    2381,
    2383,
    2385,
    2386,
    2388,
    2390,
    2392,
    2396,
    2397,
    2402,
    2403,
    2407,
    2409,
    2411,
    2413,
    2414,
    2418,
    2422,
    2426,
    2430,
    2431,
    2432,
    2435,
    2436,
    2437,
    2440,
    2443,
    2445,
    2446,
    2447,
    2452,
    2454,
    2455,
    2458,
    2460,
    2461,
    2465,
    2470,
    2473,
    2478,
    2481,
    2486,
    2488,
    2489,
    2490,
    2492,
    2495,
    2497,
    2498,
    2502,
    2503,
    2504,
    2507,
    2511,
    2514,
    2518,
    2521,
    2522,
    2525,
    2526,
    2528,
    2529,
    2532,
    2533,
    2535,
    2539,
    2540,
    2541,
    2542,
    2543,
    2544,
    2549,
    2551,
    2552,
    2556,
    2558,
    2559,
    2562,
    2563,
    2564,
    2566,
    2568,
    2569,
    2571,
    2573,
    2574,
    2577,
    2580,
    2582,
    2584,
    2589,
    2590,
    2592,
    2599,
    2602,
    2604,
    2610,
    2612,
    2615,
    2617,
    2619,
    2622,
    2625,
    2626,
    2628,
    2635,
    2641,
    2643,
    2644,
    2646,
    2647,
    2650,
    2658,
    2660,
    2661,
    2663,
    2664,
    2665,
    2667,
    2669,
    2678,
    2685,
    2687,
    2688,
    2689,
    2692,
    2694,
    2699,
    2701,
    2713,
    2716,
    2717,
    2718,
    2725,
    2727,
    2729,
    2730,
    2731,
    2733,
    2734,
    2736,
    2738,
    2745,
    2747,
    2749,
    2752,
    2761,
    2764,
    2765,
    2770,
    2774,
    2776,
    2777,
    2778,
    2779,
    2783,
    2786,
    2787,
    2791,
    2793,
    2794,
    2795,
    2797,
    2798,
    2800,
    2801,
    2803,
    2806,
    2807,
    2808,
    2816,
    2817,
    2820,
    2825,
    2828,
    2829,
    2831,
    2832,
    2834,
    2838,
    2839,
    2844,
    2847,
    2848,
    2850,
    2851,
    2854,
    2856,
    2858,
    2859,
    2873,
    2877,
    2882,
    2884,
    2885,
    2886,
    2891,
    2892,
    2895,
    2900,
    2901,
    2904,
    2905,
    2906,
    2911,
    2913,
    2915,
    2917,
    2919,
    2920,
    2923,
    2924,
    2925,
    2926,
    2927,
    2928,
    2931,
    2932,
    2934,
    2935,
    2936,
    2937,
    2939,
    2940,
    2943,
    2945,
    2947,
    2948,
    2949,
    2951,
    2954,
    2957,
    2962,
    2963,
    2964,
    2966,
    2976,
    2978,
    2979,
    2980,
    2983,
    2986,
    2988,
    2989,
    2990,
    2991,
    2999,
    3002,
    3005,
    3006,
    3008,
    3010,
    3011,
    3012,
    3018,
    3019,
    3022,
    3024,
    3026,
    3028,
    3029,
    3033,
    3035,
    3038,
    3039,
    3043,
    3049,
    3050,
    3054,
    3058,
    3061,
    3062,
    3067,
    3070,
    3073,
    3077,
    3079,
    3081,
    3082,
    3087,
    3090,
    3092,
    3093,
    3094,
    3095,
    3096,
    3099,
    3100,
    3103,
    3104,
    3105,
    3107,
    3108,
    3109,
    3110,
    3112,
    3123,
    3124,
    3126,
    3127,
    3128,
    3130,
    3135,
    3137,
    3138,
    3142,
    3143,
    3145,
    3152,
    3155,
    3157,
    3158,
    3159,
    3161,
    3164,
    3165,
    3168,
    3173,
    3175,
    3185,
    3191,
    3192,
    3194,
    3196,
    3203,
    3204,
    3207,
    3209,
    3210,
    3215,
    3219,
    3221,
    3222,
    3225,
    3228,
    3232,
    3236,
    3237,
    3240,
    3242,
    3243,
    3244,
    3246,
    3247,
    3252,
    3254,
    3259,
    3260,
    3261,
    3262,
    3265,
    3266,
    3268,
    3270,
    3272,
    3275,
    3276,
    3277,
    3285,
    3287,
    3288,
    3291,
    3296,
    3299,
    3302,
    3304,
    3305,
    3307,
    3309,
    3314,
    3317,
    3319,
    3320,
    3321,
    3324,
    3329,
    3333,
    3337,
    3340,
    3343,
    3345,
    3350,
    3351,
    3352,
    3355,
    3357,
    3358,
    3361,
    3364,
    3366,
    3368,
    3372,
    3376,
    3379,
    3382,
    3384,
    3386,
    3390,
    3392,
    3394,
    3395,
    3396,
    3398,
    3399,
    3402,
    3405,
    3407,
    3409,
    3415,
    3416,
    3418,
    3419,
    3420,
    3421,
    3423,
    3426,
    3427,
    3428,
    3431,
    3435,
    3441,
    3444,
    3446,
    3447,
    3450,
    3457,
    3458,
    3460,
    3461,
    3462,
    3466,
    3468,
    3469,
    3472,
    3473,
    3476,
    3478,
    3480,
    3481,
    3482,
    3484,
    3489,
    3490,
    3494,
    3495,
    3496,
    3504,
    3505,
    3506,
    3509,
    3511,
    3513,
    3516,
    3518,
    3523,
    3525,
    3529,
    3532,
    3535,
    3537,
    3538,
    3539,
    3546,
    3547,
    3548,
    3549,
    3554,
    3556,
    3557,
    3558,
    3560,
    3562,
    3565,
    3566,
    3567,
    3568,
    3569,
    3570,
    3571,
    3572,
    3574,
    3575,
    3576,
    3582,
    3583,
    3585,
    3587,
    3588,
    3589,
    3595,
    3596,
    3603,
    3606,
    3609,
    3610,
    3611,
    3615,
    3618,
    3622,
    3623,
    3626,
    3627,
    3629,
    3630,
    3632,
    3633,
    3634,
    3636,
    3637,
    3639,
    3641,
    3643,
    3649,
    3650,
    3651,
    3653,
    3656,
    3657,
    3658,
    3661,
    3666,
    3668,
    3669,
    3671,
    3674,
    3680,
    3681,
    3682,
    3684,
    3692,
    3697,
    3702,
    3706,
    3709,
    3711,
    3719,
    3721,
    3723,
    3725,
    3726,
    3732,
    3734,
    3735,
    3736,
    3737,
    3740,
    3743,
    3745,
    3752,
    3755,
    3756,
    3758,
    3763,
    3778,
    3780,
    3784,
    3785,
    3787,
    3788,
    3789,
    3790,
    3791,
    3792,
    3795,
    3796,
    3798,
    3799,
    3800,
    3803,
    3805,
    3809,
    3810,
    3816,
    3817,
    3819,
    3820,
    3822,
    3830,
    3835,
    3836,
    3838,
    3839,
    3840,
    3841,
    3848,
    3850,
    3855,
    3858,
    3861,
    3862,
    3864,
    3871,
    3872,
    3873,
    3876,
    3878,
    3881,
    3886,
    3887,
    3889,
    3891,
    3894,
    3896,
    3899,
    3901,
    3902,
    3903,
    3905,
    3908,
    3910,
    3911,
    3912,
    3915,
    3916,
    3917,
    3918,
    3919,
    3920,
    3923,
    3925,
    3927,
    3929,
    3934,
    3935,
    3936,
    3937,
    3941,
    3950,
    3953,
    3954,
    3957,
    3960,
    3961,
    3963,
    3966,
    3974,
    3975,
    3976,
    3978,
    3986,
    3987,
    3990,
    3991,
    3992,
    3993,
    3996
];

export const Wobo = [
    3,
    6,
    14,
    18,
    19,
    21,
    25,
    27,
    29,
    30,
    35,
    47,
    49,
    52,
    56,
    57,
    58,
    60,
    65,
    69,
    73,
    74,
    77,
    78,
    79,
    84,
    87,
    88,
    90,
    95,
    103,
    109,
    114,
    116,
    118,
    119,
    125,
    126,
    128,
    129,
    130,
    133,
    135,
    142,
    143,
    146,
    148,
    151,
    155,
    160,
    161,
    165,
    166,
    170,
    173,
    175,
    176,
    177,
    179,
    193,
    195,
    196,
    198,
    200,
    204,
    209,
    212,
    215,
    216,
    217,
    220,
    222,
    224,
    228,
    230,
    234,
    235,
    240,
    242,
    243,
    246,
    252,
    254,
    258,
    260,
    269,
    273,
    274,
    276,
    282,
    283,
    288,
    289,
    290,
    293,
    296,
    301,
    306,
    307,
    308,
    310,
    317,
    318,
    320,
    321,
    327,
    329,
    340,
    348,
    351,
    352,
    354,
    356,
    359,
    362,
    374,
    379,
    384,
    386,
    387,
    391,
    392,
    393,
    394,
    396,
    398,
    401,
    404,
    406,
    408,
    409,
    411,
    412,
    415,
    423,
    432,
    435,
    441,
    442,
    446,
    449,
    452,
    459,
    463,
    470,
    471,
    473,
    476,
    478,
    484,
    487,
    489,
    490,
    492,
    497,
    498,
    501,
    502,
    504,
    505,
    511,
    513,
    515,
    517,
    522,
    532,
    536,
    537,
    542,
    544,
    548,
    553,
    556,
    557,
    561,
    567,
    575,
    577,
    586,
    591,
    596,
    600,
    607,
    609,
    610,
    613,
    618,
    622,
    625,
    626,
    627,
    628,
    629,
    630,
    637,
    641,
    642,
    643,
    644,
    646,
    654,
    658,
    664,
    672,
    691,
    692,
    696,
    697,
    698,
    700,
    705,
    708,
    709,
    716,
    717,
    718,
    723,
    727,
    729,
    731,
    735,
    736,
    740,
    745,
    747,
    748,
    754,
    755,
    761,
    766,
    767,
    774,
    776,
    778,
    779,
    780,
    782,
    783,
    784,
    786,
    791,
    792,
    795,
    797,
    798,
    799,
    802,
    803,
    813,
    814,
    818,
    820,
    822,
    824,
    832,
    840,
    846,
    847,
    849,
    850,
    851,
    858,
    860,
    865,
    866,
    867,
    868,
    869,
    870,
    871,
    877,
    882,
    884,
    885,
    887,
    890,
    892,
    896,
    898,
    899,
    901,
    906,
    907,
    908,
    910,
    913,
    915,
    917,
    919,
    920,
    927,
    935,
    937,
    945,
    946,
    958,
    964,
    966,
    967,
    972,
    973,
    975,
    987,
    988,
    989,
    991,
    1000,
    1003,
    1004,
    1007,
    1009,
    1020,
    1023,
    1033,
    1040,
    1042,
    1045,
    1049,
    1050,
    1051,
    1052,
    1057,
    1059,
    1061,
    1063,
    1064,
    1073,
    1074,
    1075,
    1078,
    1080,
    1083,
    1090,
    1091,
    1097,
    1098,
    1107,
    1111,
    1113,
    1114,
    1116,
    1118,
    1122,
    1128,
    1129,
    1132,
    1140,
    1142,
    1144,
    1146,
    1148,
    1151,
    1154,
    1156,
    1159,
    1160,
    1165,
    1167,
    1168,
    1171,
    1175,
    1180,
    1182,
    1185,
    1194,
    1197,
    1202,
    1205,
    1208,
    1210,
    1211,
    1215,
    1216,
    1221,
    1224,
    1231,
    1232,
    1238,
    1245,
    1253,
    1257,
    1258,
    1261,
    1262,
    1263,
    1265,
    1267,
    1268,
    1270,
    1272,
    1273,
    1274,
    1278,
    1283,
    1292,
    1300,
    1303,
    1304,
    1308,
    1310,
    1311,
    1314,
    1318,
    1320,
    1321,
    1329,
    1332,
    1333,
    1335,
    1336,
    1337,
    1340,
    1342,
    1346,
    1351,
    1353,
    1358,
    1361,
    1363,
    1366,
    1367,
    1371,
    1372,
    1374,
    1378,
    1380,
    1381,
    1382,
    1384,
    1386,
    1395,
    1397,
    1399,
    1402,
    1404,
    1405,
    1406,
    1412,
    1414,
    1417,
    1421,
    1422,
    1424,
    1427,
    1431,
    1435,
    1444,
    1447,
    1451,
    1452,
    1458,
    1460,
    1462,
    1474,
    1475,
    1477,
    1479,
    1491,
    1493,
    1495,
    1499,
    1500,
    1505,
    1507,
    1513,
    1515,
    1516,
    1523,
    1524,
    1529,
    1533,
    1536,
    1542,
    1544,
    1546,
    1551,
    1552,
    1557,
    1561,
    1563,
    1564,
    1565,
    1571,
    1575,
    1577,
    1585,
    1598,
    1601,
    1603,
    1604,
    1609,
    1612,
    1617,
    1619,
    1620,
    1622,
    1623,
    1626,
    1628,
    1631,
    1641,
    1647,
    1651,
    1652,
    1654,
    1655,
    1661,
    1662,
    1663,
    1664,
    1666,
    1668,
    1671,
    1672,
    1673,
    1682,
    1685,
    1690,
    1693,
    1697,
    1698,
    1702,
    1704,
    1708,
    1711,
    1712,
    1715,
    1723,
    1726,
    1727,
    1730,
    1731,
    1732,
    1736,
    1742,
    1744,
    1746,
    1747,
    1753,
    1755,
    1756,
    1760,
    1761,
    1764,
    1773,
    1775,
    1776,
    1777,
    1780,
    1790,
    1796,
    1798,
    1802,
    1804,
    1811,
    1813,
    1814,
    1815,
    1816,
    1820,
    1822,
    1823,
    1826,
    1827,
    1833,
    1837,
    1839,
    1843,
    1848,
    1850,
    1851,
    1855,
    1859,
    1860,
    1865,
    1869,
    1872,
    1873,
    1875,
    1876,
    1879,
    1881,
    1882,
    1887,
    1889,
    1891,
    1894,
    1897,
    1898,
    1900,
    1901,
    1907,
    1910,
    1912,
    1917,
    1918,
    1927,
    1930,
    1932,
    1935,
    1936,
    1938,
    1940,
    1942,
    1947,
    1948,
    1950,
    1960,
    1963,
    1964,
    1965,
    1967,
    1968,
    1971,
    1976,
    1979,
    1982,
    1991,
    1992,
    1995,
    2005,
    2018,
    2026,
    2027,
    2028,
    2029,
    2033,
    2034,
    2035,
    2036,
    2039,
    2044,
    2055,
    2057,
    2059,
    2060,
    2062,
    2063,
    2064,
    2071,
    2077,
    2078,
    2081,
    2082,
    2083,
    2084,
    2088,
    2094,
    2095,
    2106,
    2108,
    2109,
    2112,
    2116,
    2117,
    2140,
    2143,
    2147,
    2149,
    2151,
    2153,
    2157,
    2159,
    2162,
    2171,
    2172,
    2174,
    2184,
    2189,
    2190,
    2192,
    2195,
    2199,
    2201,
    2203,
    2205,
    2208,
    2210,
    2213,
    2216,
    2217,
    2219,
    2221,
    2223,
    2233,
    2234,
    2237,
    2239,
    2240,
    2241,
    2242,
    2244,
    2246,
    2254,
    2258,
    2264,
    2265,
    2267,
    2268,
    2271,
    2278,
    2284,
    2289,
    2290,
    2293,
    2296,
    2297,
    2300,
    2301,
    2302,
    2304,
    2305,
    2306,
    2308,
    2310,
    2315,
    2324,
    2325,
    2328,
    2330,
    2340,
    2344,
    2351,
    2354,
    2357,
    2359,
    2362,
    2372,
    2374,
    2376,
    2377,
    2384,
    2387,
    2395,
    2399,
    2404,
    2405,
    2410,
    2412,
    2415,
    2416,
    2420,
    2423,
    2424,
    2425,
    2429,
    2433,
    2438,
    2441,
    2442,
    2448,
    2449,
    2450,
    2469,
    2471,
    2472,
    2475,
    2477,
    2479,
    2480,
    2483,
    2484,
    2491,
    2493,
    2496,
    2500,
    2515,
    2516,
    2517,
    2519,
    2520,
    2531,
    2537,
    2538,
    2550,
    2572,
    2575,
    2578,
    2585,
    2588,
    2591,
    2593,
    2597,
    2598,
    2600,
    2601,
    2605,
    2609,
    2614,
    2616,
    2618,
    2621,
    2624,
    2627,
    2629,
    2633,
    2634,
    2638,
    2639,
    2642,
    2648,
    2649,
    2652,
    2653,
    2654,
    2655,
    2659,
    2666,
    2671,
    2676,
    2677,
    2679,
    2681,
    2686,
    2690,
    2691,
    2693,
    2695,
    2698,
    2702,
    2703,
    2704,
    2705,
    2709,
    2710,
    2714,
    2715,
    2720,
    2724,
    2726,
    2737,
    2740,
    2742,
    2743,
    2744,
    2746,
    2750,
    2757,
    2758,
    2760,
    2762,
    2766,
    2768,
    2769,
    2771,
    2775,
    2784,
    2789,
    2790,
    2796,
    2799,
    2802,
    2809,
    2810,
    2811,
    2812,
    2813,
    2819,
    2822,
    2827,
    2835,
    2837,
    2843,
    2846,
    2852,
    2857,
    2860,
    2861,
    2863,
    2865,
    2866,
    2869,
    2872,
    2874,
    2875,
    2876,
    2878,
    2880,
    2883,
    2888,
    2889,
    2897,
    2908,
    2909,
    2914,
    2916,
    2921,
    2922,
    2929,
    2930,
    2933,
    2941,
    2942,
    2950,
    2953,
    2956,
    2958,
    2961,
    2967,
    2968,
    2973,
    2975,
    2981,
    2984,
    2985,
    2992,
    2993,
    2994,
    2995,
    2997,
    3003,
    3013,
    3015,
    3017,
    3021,
    3025,
    3034,
    3041,
    3047,
    3051,
    3052,
    3055,
    3059,
    3065,
    3068,
    3069,
    3078,
    3083,
    3086,
    3089,
    3097,
    3101,
    3111,
    3114,
    3115,
    3116,
    3118,
    3120,
    3122,
    3125,
    3129,
    3131,
    3132,
    3133,
    3140,
    3141,
    3147,
    3148,
    3154,
    3156,
    3169,
    3171,
    3172,
    3181,
    3182,
    3183,
    3184,
    3186,
    3187,
    3188,
    3190,
    3197,
    3198,
    3199,
    3202,
    3208,
    3214,
    3216,
    3220,
    3227,
    3230,
    3231,
    3235,
    3241,
    3245,
    3248,
    3250,
    3251,
    3255,
    3257,
    3263,
    3264,
    3271,
    3279,
    3280,
    3281,
    3282,
    3283,
    3284,
    3286,
    3294,
    3301,
    3303,
    3310,
    3312,
    3313,
    3318,
    3322,
    3325,
    3327,
    3328,
    3334,
    3335,
    3344,
    3346,
    3347,
    3348,
    3349,
    3353,
    3360,
    3363,
    3365,
    3367,
    3370,
    3373,
    3385,
    3387,
    3388,
    3397,
    3400,
    3401,
    3403,
    3408,
    3410,
    3417,
    3422,
    3425,
    3429,
    3432,
    3434,
    3438,
    3439,
    3440,
    3445,
    3449,
    3455,
    3456,
    3459,
    3463,
    3471,
    3492,
    3493,
    3499,
    3507,
    3510,
    3512,
    3515,
    3520,
    3524,
    3527,
    3528,
    3531,
    3540,
    3543,
    3551,
    3552,
    3553,
    3559,
    3563,
    3564,
    3573,
    3581,
    3590,
    3592,
    3593,
    3594,
    3597,
    3598,
    3600,
    3604,
    3605,
    3607,
    3616,
    3617,
    3621,
    3624,
    3625,
    3635,
    3638,
    3640,
    3644,
    3645,
    3647,
    3648,
    3652,
    3654,
    3655,
    3659,
    3660,
    3662,
    3664,
    3667,
    3670,
    3672,
    3673,
    3677,
    3679,
    3685,
    3686,
    3687,
    3688,
    3690,
    3699,
    3700,
    3701,
    3703,
    3705,
    3708,
    3712,
    3714,
    3715,
    3716,
    3718,
    3722,
    3724,
    3730,
    3731,
    3738,
    3739,
    3741,
    3744,
    3746,
    3748,
    3750,
    3751,
    3753,
    3754,
    3759,
    3760,
    3761,
    3764,
    3767,
    3771,
    3773,
    3776,
    3786,
    3793,
    3794,
    3801,
    3802,
    3806,
    3808,
    3814,
    3821,
    3823,
    3825,
    3826,
    3828,
    3829,
    3831,
    3832,
    3837,
    3842,
    3843,
    3844,
    3845,
    3852,
    3856,
    3859,
    3866,
    3867,
    3869,
    3874,
    3875,
    3877,
    3879,
    3883,
    3884,
    3885,
    3892,
    3893,
    3904,
    3906,
    3907,
    3914,
    3931,
    3933,
    3938,
    3940,
    3942,
    3943,
    3945,
    3946,
    3947,
    3951,
    3955,
    3959,
    3965,
    3967,
    3968,
    3970,
    3972,
    3977,
    3979,
    3981,
    3982,
    3984,
    3985,
    3995,
    3997
];

export const Xeba = [
    2,
    4,
    5,
    9,
    11,
    20,
    41,
    45,
    62,
    66,
    68,
    70,
    81,
    99,
    101,
    105,
    106,
    107,
    112,
    122,
    124,
    131,
    137,
    139,
    140,
    149,
    153,
    167,
    171,
    178,
    180,
    188,
    192,
    194,
    203,
    207,
    211,
    214,
    237,
    245,
    248,
    251,
    255,
    256,
    263,
    292,
    298,
    300,
    303,
    304,
    305,
    309,
    314,
    324,
    326,
    328,
    330,
    335,
    337,
    338,
    339,
    341,
    344,
    345,
    346,
    361,
    367,
    375,
    376,
    382,
    389,
    395,
    399,
    402,
    405,
    417,
    418,
    422,
    424,
    427,
    433,
    444,
    445,
    447,
    453,
    454,
    458,
    465,
    468,
    482,
    483,
    488,
    494,
    496,
    500,
    503,
    507,
    525,
    531,
    538,
    539,
    546,
    549,
    552,
    554,
    555,
    563,
    564,
    565,
    570,
    578,
    584,
    590,
    592,
    594,
    595,
    601,
    602,
    634,
    648,
    653,
    669,
    684,
    688,
    693,
    701,
    703,
    704,
    711,
    725,
    730,
    732,
    744,
    749,
    757,
    762,
    763,
    775,
    781,
    785,
    787,
    789,
    794,
    796,
    805,
    808,
    809,
    811,
    816,
    838,
    843,
    852,
    854,
    862,
    874,
    876,
    879,
    880,
    883,
    897,
    912,
    921,
    922,
    924,
    939,
    940,
    944,
    950,
    955,
    956,
    960,
    962,
    963,
    971,
    979,
    980,
    982,
    984,
    992,
    993,
    994,
    995,
    999,
    1001,
    1002,
    1006,
    1016,
    1024,
    1025,
    1026,
    1027,
    1029,
    1035,
    1043,
    1055,
    1060,
    1062,
    1069,
    1077,
    1084,
    1089,
    1094,
    1100,
    1105,
    1106,
    1109,
    1110,
    1112,
    1117,
    1127,
    1131,
    1134,
    1139,
    1147,
    1149,
    1153,
    1155,
    1162,
    1166,
    1169,
    1172,
    1176,
    1177,
    1179,
    1190,
    1191,
    1193,
    1196,
    1206,
    1207,
    1219,
    1225,
    1233,
    1235,
    1236,
    1243,
    1246,
    1248,
    1254,
    1255,
    1256,
    1260,
    1264,
    1266,
    1269,
    1284,
    1290,
    1294,
    1299,
    1316,
    1324,
    1327,
    1330,
    1331,
    1343,
    1347,
    1348,
    1350,
    1352,
    1356,
    1359,
    1360,
    1364,
    1373,
    1383,
    1388,
    1391,
    1398,
    1407,
    1410,
    1413,
    1415,
    1430,
    1432,
    1433,
    1437,
    1456,
    1464,
    1480,
    1481,
    1486,
    1488,
    1490,
    1503,
    1506,
    1519,
    1520,
    1521,
    1522,
    1526,
    1527,
    1538,
    1541,
    1547,
    1553,
    1554,
    1555,
    1559,
    1560,
    1572,
    1576,
    1581,
    1584,
    1590,
    1599,
    1600,
    1610,
    1614,
    1625,
    1635,
    1640,
    1646,
    1648,
    1657,
    1659,
    1667,
    1675,
    1679,
    1687,
    1695,
    1703,
    1707,
    1709,
    1710,
    1713,
    1716,
    1717,
    1718,
    1721,
    1724,
    1728,
    1734,
    1738,
    1740,
    1745,
    1758,
    1766,
    1774,
    1778,
    1782,
    1784,
    1785,
    1786,
    1788,
    1789,
    1792,
    1795,
    1805,
    1808,
    1810,
    1824,
    1835,
    1845,
    1852,
    1853,
    1857,
    1861,
    1878,
    1880,
    1884,
    1886,
    1888,
    1905,
    1916,
    1933,
    1934,
    1937,
    1939,
    1941,
    1943,
    1945,
    1946,
    1949,
    1955,
    1957,
    1959,
    1966,
    1973,
    1983,
    2012,
    2015,
    2017,
    2020,
    2025,
    2037,
    2042,
    2043,
    2046,
    2049,
    2050,
    2051,
    2065,
    2070,
    2072,
    2080,
    2087,
    2089,
    2091,
    2104,
    2110,
    2115,
    2118,
    2123,
    2128,
    2130,
    2131,
    2134,
    2144,
    2154,
    2156,
    2168,
    2169,
    2173,
    2177,
    2196,
    2198,
    2200,
    2202,
    2214,
    2224,
    2226,
    2236,
    2238,
    2243,
    2247,
    2250,
    2253,
    2269,
    2272,
    2280,
    2282,
    2294,
    2298,
    2309,
    2311,
    2312,
    2321,
    2334,
    2336,
    2338,
    2339,
    2343,
    2345,
    2350,
    2352,
    2355,
    2356,
    2360,
    2369,
    2373,
    2378,
    2382,
    2389,
    2391,
    2393,
    2394,
    2398,
    2401,
    2406,
    2408,
    2417,
    2419,
    2427,
    2439,
    2444,
    2451,
    2453,
    2457,
    2459,
    2463,
    2464,
    2466,
    2467,
    2474,
    2476,
    2482,
    2485,
    2499,
    2505,
    2508,
    2509,
    2512,
    2513,
    2524,
    2527,
    2530,
    2536,
    2545,
    2547,
    2548,
    2553,
    2554,
    2557,
    2560,
    2565,
    2567,
    2570,
    2579,
    2581,
    2583,
    2586,
    2587,
    2594,
    2595,
    2596,
    2603,
    2606,
    2607,
    2620,
    2623,
    2630,
    2631,
    2640,
    2645,
    2651,
    2657,
    2662,
    2668,
    2672,
    2674,
    2680,
    2682,
    2683,
    2684,
    2696,
    2697,
    2706,
    2707,
    2708,
    2711,
    2712,
    2721,
    2722,
    2723,
    2728,
    2735,
    2739,
    2741,
    2748,
    2751,
    2753,
    2754,
    2755,
    2772,
    2773,
    2780,
    2781,
    2782,
    2785,
    2788,
    2792,
    2804,
    2815,
    2818,
    2821,
    2823,
    2824,
    2826,
    2830,
    2833,
    2836,
    2841,
    2845,
    2849,
    2855,
    2862,
    2864,
    2867,
    2870,
    2871,
    2881,
    2893,
    2894,
    2896,
    2898,
    2899,
    2902,
    2903,
    2907,
    2912,
    2944,
    2946,
    2952,
    2955,
    2959,
    2960,
    2965,
    2969,
    2970,
    2971,
    2974,
    2977,
    2996,
    3014,
    3020,
    3023,
    3030,
    3031,
    3032,
    3036,
    3040,
    3042,
    3044,
    3045,
    3046,
    3048,
    3060,
    3063,
    3066,
    3074,
    3075,
    3080,
    3084,
    3088,
    3091,
    3098,
    3102,
    3106,
    3113,
    3119,
    3121,
    3134,
    3136,
    3139,
    3149,
    3150,
    3151,
    3153,
    3160,
    3163,
    3167,
    3174,
    3176,
    3177,
    3178,
    3179,
    3180,
    3189,
    3201,
    3206,
    3211,
    3212,
    3218,
    3223,
    3224,
    3234,
    3253,
    3256,
    3258,
    3267,
    3273,
    3278,
    3289,
    3290,
    3292,
    3293,
    3295,
    3298,
    3306,
    3308,
    3311,
    3316,
    3323,
    3326,
    3330,
    3331,
    3336,
    3338,
    3339,
    3341,
    3354,
    3356,
    3369,
    3374,
    3375,
    3377,
    3378,
    3381,
    3389,
    3391,
    3411,
    3412,
    3413,
    3414,
    3430,
    3433,
    3437,
    3442,
    3451,
    3453,
    3454,
    3464,
    3467,
    3479,
    3483,
    3486,
    3488,
    3497,
    3498,
    3500,
    3501,
    3502,
    3503,
    3514,
    3519,
    3522,
    3526,
    3530,
    3533,
    3534,
    3541,
    3545,
    3550,
    3555,
    3561,
    3580,
    3586,
    3591,
    3608,
    3612,
    3613,
    3614,
    3619,
    3620,
    3631,
    3663,
    3675,
    3676,
    3678,
    3683,
    3689,
    3691,
    3693,
    3694,
    3696,
    3698,
    3704,
    3707,
    3710,
    3713,
    3717,
    3720,
    3727,
    3728,
    3742,
    3749,
    3766,
    3769,
    3770,
    3772,
    3774,
    3775,
    3779,
    3782,
    3783,
    3797,
    3804,
    3807,
    3812,
    3813,
    3815,
    3818,
    3824,
    3833,
    3847,
    3854,
    3857,
    3860,
    3863,
    3865,
    3868,
    3870,
    3880,
    3882,
    3888,
    3890,
    3895,
    3900,
    3909,
    3921,
    3922,
    3924,
    3926,
    3928,
    3930,
    3939,
    3944,
    3948,
    3949,
    3952,
    3956,
    3958,
    3962,
    3971,
    3973,
    3980,
    3983,
    3988,
    3994,
    3999
];

export const Pelu = [
    1,
    15,
    16,
    32,
    40,
    51,
    53,
    61,
    71,
    83,
    91,
    93,
    98,
    104,
    108,
    113,
    150,
    156,
    157,
    187,
    190,
    191,
    199,
    202,
    219,
    221,
    226,
    236,
    250,
    253,
    266,
    267,
    270,
    271,
    278,
    279,
    291,
    299,
    315,
    336,
    342,
    350,
    360,
    363,
    366,
    370,
    381,
    407,
    426,
    428,
    451,
    456,
    475,
    477,
    481,
    508,
    509,
    510,
    540,
    543,
    550,
    558,
    572,
    580,
    588,
    593,
    598,
    603,
    606,
    616,
    624,
    633,
    649,
    659,
    662,
    667,
    668,
    670,
    673,
    683,
    694,
    715,
    720,
    753,
    806,
    812,
    823,
    826,
    841,
    864,
    886,
    893,
    905,
    916,
    918,
    930,
    933,
    957,
    959,
    961,
    969,
    976,
    996,
    1013,
    1014,
    1015,
    1022,
    1030,
    1032,
    1034,
    1036,
    1037,
    1038,
    1047,
    1076,
    1086,
    1088,
    1095,
    1103,
    1121,
    1130,
    1136,
    1141,
    1143,
    1158,
    1178,
    1183,
    1189,
    1192,
    1203,
    1209,
    1227,
    1230,
    1237,
    1250,
    1295,
    1296,
    1302,
    1307,
    1309,
    1323,
    1344,
    1368,
    1387,
    1409,
    1419,
    1443,
    1446,
    1450,
    1454,
    1455,
    1467,
    1468,
    1469,
    1476,
    1478,
    1482,
    1498,
    1509,
    1531,
    1537,
    1543,
    1549,
    1570,
    1574,
    1578,
    1593,
    1629,
    1630,
    1632,
    1634,
    1650,
    1656,
    1670,
    1680,
    1684,
    1692,
    1700,
    1714,
    1719,
    1722,
    1729,
    1735,
    1743,
    1752,
    1765,
    1779,
    1793,
    1825,
    1832,
    1844,
    1847,
    1849,
    1862,
    1863,
    1877,
    1885,
    1892,
    1896,
    1899,
    1903,
    1921,
    1952,
    1970,
    1975,
    1977,
    1980,
    1987,
    1996,
    2001,
    2006,
    2031,
    2032,
    2038,
    2069,
    2075,
    2076,
    2098,
    2103,
    2120,
    2132,
    2137,
    2139,
    2150,
    2155,
    2161,
    2163,
    2165,
    2179,
    2186,
    2197,
    2204,
    2206,
    2212,
    2251,
    2261,
    2263,
    2275,
    2276,
    2286,
    2292,
    2295,
    2342,
    2346,
    2361,
    2400,
    2421,
    2428,
    2434,
    2456,
    2462,
    2468,
    2487,
    2494,
    2501,
    2506,
    2510,
    2523,
    2534,
    2546,
    2555,
    2561,
    2576,
    2608,
    2611,
    2613,
    2632,
    2636,
    2637,
    2656,
    2670,
    2673,
    2675,
    2700,
    2719,
    2732,
    2756,
    2759,
    2763,
    2767,
    2805,
    2814,
    2840,
    2842,
    2853,
    2868,
    2879,
    2887,
    2890,
    2910,
    2918,
    2938,
    2972,
    2982,
    2987,
    2998,
    3000,
    3001,
    3004,
    3007,
    3009,
    3016,
    3027,
    3037,
    3053,
    3056,
    3057,
    3064,
    3071,
    3072,
    3076,
    3085,
    3117,
    3144,
    3146,
    3162,
    3166,
    3170,
    3193,
    3195,
    3200,
    3205,
    3213,
    3217,
    3226,
    3229,
    3233,
    3238,
    3239,
    3249,
    3269,
    3274,
    3297,
    3300,
    3315,
    3332,
    3342,
    3359,
    3362,
    3371,
    3380,
    3383,
    3393,
    3404,
    3406,
    3424,
    3436,
    3443,
    3448,
    3452,
    3465,
    3470,
    3474,
    3475,
    3477,
    3485,
    3487,
    3491,
    3508,
    3517,
    3521,
    3536,
    3542,
    3544,
    3577,
    3578,
    3579,
    3584,
    3599,
    3601,
    3602,
    3628,
    3642,
    3646,
    3665,
    3695,
    3729,
    3733,
    3747,
    3757,
    3762,
    3765,
    3768,
    3777,
    3781,
    3811,
    3827,
    3834,
    3846,
    3849,
    3851,
    3853,
    3897,
    3898,
    3913,
    3932,
    3964,
    3969,
    3989,
    3998
];